.f-dialog-connect-wallet {
    z-index: 1000 !important;
    .f-list-connect-wallet {
        .f-list-item {
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-height: 70px;
            padding: 0.75rem;
            border: 1px solid white;
            margin-bottom: 1rem;
            img {
                max-width: 40px;
            }
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.f-dialog-content {
    z-index: 1000 !important;
}