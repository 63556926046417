.f-m-auto {
    margin: 0px auto;
}

.withdrawals_container {
    width: 100%;

    .f-table-wrapper {
        width: 100%;
        margin-top: 2rem;
    }
}